import React from "react";

const Main = (props) => {
   
    return (
        <div className="relative w-full h-screen overflo\
        ">
            {/* Background Video */}
            <video
                className="absolute inset-0 w-full h-full object-cover"
                src={require('../assets/bg2.mp4')}
                autoPlay
                loop
                muted
                playsInline
                poster="/path-to-your-fallback-image.jpg"
            >
            </video>
            <img className="absolute l-0 random-move3" src={require('../assets/cat.gif')}/>
            <img className="absolute l-0 random-move" src={require('../assets/wif.png')}/>
            <img className="absolute l-0 random-move2" src={require('../assets/buy.png')}/>

            <div className="absolute inset-0 flex flex-col items-center justify-center text-center bg-black bg-opacity-50 text-white">
                <h1 className="text-xl font-bold mb-8 text-[gold] animate-bounce">🚨 You’re Just a Few Buttons Away from Becoming a Millionaire! 🚨</h1>
                <img  className="max-w-[600px] w-[100%] min-w-[300px] mb-10 " src={require('../assets/p.gif')}/>

                <div className="flex space-x-4">
                    <button className="animate-pulse px-6 py-3 bg-[gold]  rounded-lg text-black text-lg font-medium">
                         Telegram
                    </button>
                    <button className="animate-pulse px-6 py-3 bg-[gold] text-black rounded-lg text-lg font-medium">
                         Twitter
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Main;
