import logo from './logo.svg';
import './App.css';
import VideoBackground from './components/VideoBackground';
import { useState } from 'react';
import Main from './components/Main';
import About from './components/About';

function App() {
  const [isPressed, setIsPressed] = useState(false)
  return (
    <div >
      {
        isPressed ? (
          <>
          <Main />
          <About/>
          </>
        )
          : (
            <VideoBackground isPressed={isPressed} setIsPressed={setIsPressed} />
          )
      }
    </div>
  );
}

export default App;
