import React from 'react'

const About = () => {
    return (
        <div className='bg2 h-full w-full border-t-4 border-yellow-600'>
            <section class="text-gray-600 body-font">
                <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <img class="earthquake lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src={require('../assets/buy.png')} />
                    <div class="text-center lg:w-2/3 w-full">
                        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-[gold]">Introducing Press Buttons ($BUTTONS) – the meme coin that could change everything! 🚀</h1>
                        <p class="mb-8 leading-relaxed md:text-lg text-white">You’ve heard it before: life’s all about making the right moves. Well, Press Buttons is just a couple of taps away from potentially making you a crypto legend. 💰 Each press gets you closer to the future you’ve been dreaming of, and this time, it's literally in your hands.

Don't overthink it. The next big thing might just be a few clicks away. Will you press the button? 🖱️

#PressButtons #BUTTONS #CryptoRevolution #MemeCoin #MillionaireInTheMaking</p>
                        {/* <div class="flex justify-center">
                            <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
                            <button class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button>
                        </div> */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About