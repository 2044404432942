import React from "react";

const VideoBackground = (props) => {
    const {isPressed, setIsPressed} = props
    const buttonPressed = () =>{
        setIsPressed(true)
    }
    return (
        <div className="relative w-full h-screen">
            {/* Background Video */}
            <video
                className="absolute inset-0 w-full h-full object-cover"
                src={require('../assets/bg.mp4')}
                autoPlay
                loop
                muted
                playsInline
                poster="/path-to-your-fallback-image.jpg"
            >
            </video>
            <div className="absolute inset-0 flex flex-col items-center justify-center text-center bg-black bg-opacity-50 text-white">
                <h1 className="text-xl font-bold mb-8 text-[gold] animate-bounce">🚨 You’re Just a Few Buttons Away from Becoming a Millionaire! 🚨</h1>
                <img onClick={buttonPressed} className="max-w-[300px] mb-10 animate-pulse" src={require('../assets/open.webp')}/>

                <div className="flex space-x-4">
                    <button onClick={buttonPressed} className="animate-pulse px-6 py-3 bg-green-900 hover:bg-green-800 rounded-lg text-lg font-medium">
                        ENTER RABBIT HOLE
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VideoBackground;
